<template>
  <div>
    <a-form-item label="内部API服务器域名">
      <a-input
        v-decorator="['china_telecom_api_account_form.api_server_domain', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.api_server_domain,
          rules: [{ required: true, message: '请输入内部API服务器域名' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="接口类型">
      <a-select
        @change="selectAccountType"
        v-decorator="['china_telecom_api_account_form.account_type', {
          initialValue: chinaTelecomApiAccountForm.account_type,
          rules: [{ required: true, message: '请选择接口类型' }
          ]
        }]"
      >
        <a-select-option key="CMP" value="CMP">
          CMP
        </a-select-option>
        <a-select-option key="5GCMP" value="5GCMP">
          5G CMP
        </a-select-option>
        <a-select-option key="DCP" value="DCP">
          DCP
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="API访问路径">
      <a-input
        v-decorator="['china_telecom_api_account_form.api_url', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.api_url,
          rules: [{ required: true, message: '请输入API访问路径' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="接口用户名">
      <a-input
        v-decorator="['china_telecom_api_account_form.api_username', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.api_username,
          rules: [{ required: true, message: '请输入接口用户名' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="接口密码">
      <a-input
        v-decorator="['china_telecom_api_account_form.api_password', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.api_password,
          rules: [{ required: true, message: '请输入接口密码' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="接口密钥" v-show="isShowCMPParams">
      <a-input
        v-decorator="['china_telecom_api_account_form.api_secret_key', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.api_secret_key,
          rules: [{ max: 50, message: '最多50个字符' }]
        }]"
      />
    </a-form-item>

    <a-form-item label="Rest 用户名" v-show="isShowDCPParams">
      <a-input
        v-decorator="['china_telecom_api_account_form.dcp_rest_username', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.dcp_rest_username,
          rules: [{ max: 50, message: '最多50个字符' }]
        }]"
      />
    </a-form-item>

    <a-form-item label="Rest 密码" v-show="isShowDCPParams">
      <a-input
        v-decorator="['china_telecom_api_account_form.dcp_rest_password', {
          normalize: this.$lodash.trim,
          initialValue: chinaTelecomApiAccountForm.dcp_rest_password,
          rules: [{ max: 50, message: '最多50个字符' }]
        }]"
      />
    </a-form-item>

    <a-form-item label="调用限频">
      <a-input-group compact>
        <a-form-item :style="{ display: 'inline-block', width: '55%', marginBottom: 0 }">
          <a-space>
            <a-input-number
              style="width: 135px"
              :min="1"
              :max="10000000"
              v-decorator="['china_telecom_api_account_form.rate_count', {
                initialValue: chinaTelecomApiAccountForm.rate_count == 0 ? undefined : chinaTelecomApiAccountForm.rate_count,
                rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
              }]"
            />
            <span>次</span>
          </a-space>
        </a-form-item>

        <a-form-item :style="{ display: 'inline-block', width: '40%', marginBottom: 0 }">
          <a-select
            style="width: 140px"
            v-decorator="['china_telecom_api_account_form.rate_time_unit', {
              initialValue: chinaTelecomApiAccountForm.rate_time_unit
            }]"
          >
            <a-select-option value="second">
              每秒
            </a-select-option>
            <a-select-option value="minute">
              每分
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-input-group>
    </a-form-item>
  </div>
</template>

<script>
export default {
  name: 'EditChinaTelecomApiAccountForm',
  props: {
    chinaTelecomApiAccountForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShowCMPParams: this.chinaTelecomApiAccountForm.account_type === 'CMP',
      isShow5GCMPParams: this.chinaTelecomApiAccountForm.account_type === '5GCMP',
      isShowDCPParams: this.chinaTelecomApiAccountForm.account_type === 'DCP'
    }
  },
  methods: {
    selectAccountType(value) {
      if (value === 'CMP') {
        this.isShowCMPParams = true
        this.isShow5GCMPParams = false
        this.isShowDCPParams = false
      } else if (value === '5GCMP') {
        this.isShowCMPParams = false
        this.isShow5GCMPParams = true
        this.isShowDCPParams = false
      } else {
        this.isShowCMPParams = false
        this.isShow5GCMPParams = false
        this.isShowDCPParams = true
      }
    }
  }
}
</script>

<style scoped>

</style>
