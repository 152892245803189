var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-item',{attrs:{"label":"内部API服务器域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.api_server_domain', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.api_server_domain,
        rules: [{ required: true, message: '请输入内部API服务器域名' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_telecom_api_account_form.api_server_domain', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.api_server_domain,\n        rules: [{ required: true, message: '请输入内部API服务器域名' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"接口类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.account_type', {
        initialValue: _vm.chinaTelecomApiAccountForm.account_type,
        rules: [{ required: true, message: '请选择接口类型' }
        ]
      }]),expression:"['china_telecom_api_account_form.account_type', {\n        initialValue: chinaTelecomApiAccountForm.account_type,\n        rules: [{ required: true, message: '请选择接口类型' }\n        ]\n      }]"}],on:{"change":_vm.selectAccountType}},[_c('a-select-option',{key:"CMP",attrs:{"value":"CMP"}},[_vm._v(" CMP ")]),_c('a-select-option',{key:"5GCMP",attrs:{"value":"5GCMP"}},[_vm._v(" 5G CMP ")]),_c('a-select-option',{key:"DCP",attrs:{"value":"DCP"}},[_vm._v(" DCP ")])],1)],1),_c('a-form-item',{attrs:{"label":"API访问路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.api_url', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.api_url,
        rules: [{ required: true, message: '请输入API访问路径' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_telecom_api_account_form.api_url', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.api_url,\n        rules: [{ required: true, message: '请输入API访问路径' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"接口用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.api_username', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.api_username,
        rules: [{ required: true, message: '请输入接口用户名' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_telecom_api_account_form.api_username', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.api_username,\n        rules: [{ required: true, message: '请输入接口用户名' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"接口密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.api_password', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.api_password,
        rules: [{ required: true, message: '请输入接口密码' },
                { max: 50, message: '最多50个字符' }
        ]
      }]),expression:"['china_telecom_api_account_form.api_password', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.api_password,\n        rules: [{ required: true, message: '请输入接口密码' },\n                { max: 50, message: '最多50个字符' }\n        ]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowCMPParams),expression:"isShowCMPParams"}],attrs:{"label":"接口密钥"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.api_secret_key', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.api_secret_key,
        rules: [{ max: 50, message: '最多50个字符' }]
      }]),expression:"['china_telecom_api_account_form.api_secret_key', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.api_secret_key,\n        rules: [{ max: 50, message: '最多50个字符' }]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDCPParams),expression:"isShowDCPParams"}],attrs:{"label":"Rest 用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.dcp_rest_username', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.dcp_rest_username,
        rules: [{ max: 50, message: '最多50个字符' }]
      }]),expression:"['china_telecom_api_account_form.dcp_rest_username', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.dcp_rest_username,\n        rules: [{ max: 50, message: '最多50个字符' }]\n      }]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDCPParams),expression:"isShowDCPParams"}],attrs:{"label":"Rest 密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.dcp_rest_password', {
        normalize: this.$lodash.trim,
        initialValue: _vm.chinaTelecomApiAccountForm.dcp_rest_password,
        rules: [{ max: 50, message: '最多50个字符' }]
      }]),expression:"['china_telecom_api_account_form.dcp_rest_password', {\n        normalize: this.$lodash.trim,\n        initialValue: chinaTelecomApiAccountForm.dcp_rest_password,\n        rules: [{ max: 50, message: '最多50个字符' }]\n      }]"}]})],1),_c('a-form-item',{attrs:{"label":"调用限频"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '55%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.rate_count', {
              initialValue: _vm.chinaTelecomApiAccountForm.rate_count == 0 ? undefined : _vm.chinaTelecomApiAccountForm.rate_count,
              rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
            }]),expression:"['china_telecom_api_account_form.rate_count', {\n              initialValue: chinaTelecomApiAccountForm.rate_count == 0 ? undefined : chinaTelecomApiAccountForm.rate_count,\n              rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]\n            }]"}],staticStyle:{"width":"135px"},attrs:{"min":1,"max":10000000}}),_c('span',[_vm._v("次")])],1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '40%', marginBottom: 0 })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['china_telecom_api_account_form.rate_time_unit', {
            initialValue: _vm.chinaTelecomApiAccountForm.rate_time_unit
          }]),expression:"['china_telecom_api_account_form.rate_time_unit', {\n            initialValue: chinaTelecomApiAccountForm.rate_time_unit\n          }]"}],staticStyle:{"width":"140px"}},[_c('a-select-option',{attrs:{"value":"second"}},[_vm._v(" 每秒 ")]),_c('a-select-option',{attrs:{"value":"minute"}},[_vm._v(" 每分 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }